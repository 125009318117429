export default {
    init() {

        setTimeout(() => {
            const heroImgWrapper = document.querySelector('.material .hero-img-wrapper .hero-img-rect');
                if (heroImgWrapper) {
                    heroImgWrapper.classList.add('outline-animation');
                }
        }, 1000);

    },
    finalize() {
      // JavaScript to be fired on the home page, after the init JS
    },
};
