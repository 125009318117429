import videojs from 'video.js';
import Splide from '@splidejs/splide';
import { Grid } from '@splidejs/splide-extension-grid';
import GLightbox from 'glightbox';

export default {
    init() {

      var player = videojs('filmat-video', {});
      console.log(player);

      const lightbox = GLightbox({
        touchNavigation: true,
        loop: true,
        autoplayVideos: true,
        preload: false,
      });
    
      lightbox.init();

      const button = new Splide('#button-carousel', {
        width       : '100%',
        perPage     : 1,
        autoplay    : true,
        interval    : 6000,
        type        : 'fade',
        rewind      : true,
        pagination  : false,
        arrows      : false,
      }).mount();
      
      const photo = new Splide('#photo-carousel', {
        width       : '100%',
        perPage     : 1,
        type        : 'fade',
        rewind      : true,
        pagination  : false,
        arrows      : false,
      }).mount();
  
      button.sync( photo );

      const splideGrid = new Splide('#grid-carousel', {
        height      : '530px',
        type        : 'loop',
        rewind      : true,
        perPage     : 2,
        perMove     : 2,
        pagination  : true,
        arrows      : true,
        gap         : '30px',
        updateOnMove: true,
        grid: {
          dimensions: [ 
            [1, 1], [2, 1], 
            [1, 1], [2, 1], 
            [1, 1], [2, 1], 
            [1, 1], [2, 1],
            [1, 1], [2, 1], 
            [1, 1], [2, 1], 
            [1, 1], [2, 1], 
            [1, 1], [2, 1],
          ],
          gap : {
            row: '30px',
            col: '30px',
          },
        },
        breakpoints: {
          991.98: {
            perPage: 1,
            grid: false,
            height: false,
            gap: false,
          },
          1199.98: {
            height: '451px',
          },
          1399.98: {
            height: '538px',
          },
          1599.98: {
            height: '410px',
          },
          1799.98: {
            height: '476px',
          },
        },
      });

      splideGrid.mount( { Grid } );

      // Refresh Splide instance on window resize
      const refreshSplide = () => {
        splideGrid.destroy();
        splideGrid.mount();
      };

      window.addEventListener('resize', () => {        
        refreshSplide();
      });

    },
    finalize() {
      // JavaScript to be fired on the home page, after the init JS
    },
  };
  