import sal from 'sal.js'
export default {
  init() {

    sal();

    let cookiebtn = document.querySelector('button.cookies-popup--btn');

    if(cookiebtn){
      cookiebtn.addEventListener('click', ()=>{
        const d = new Date();
        d.setTime(d.getTime() + (30*24*60*60*1000));
        let expires = 'expires='+ d.toUTCString();
        document.cookie = 'cookiesaccept=1;' + expires + ';path=/';
        document.querySelector('.cookies-popup').style.display='none';
      });
    }
 
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
};
