export default {
    init() {
        
    document.getElementById('back-button').addEventListener('click', () => {
        history.back();
    });

    },
    finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
    },
};