import Splide from '@splidejs/splide';
import GLightbox from 'glightbox';

export default {
    init() {

        const lightbox = GLightbox({
            touchNavigation: true,
            loop: true,
            autoplayVideos: true,
            preload: false,
        });
      
        lightbox.init();

        new Splide('#technology-gallery-carousel', {
            height      : '720px',
            type        : 'loop',
            rewind      : true,
            perPage     : 1,
            pagination  : true,
            arrows      : true,
            breakpoints: {
                991.98: {
                  height: '360px',
                },
                1199.98: {
                    height: '420px',
                },
                1399.98: {
                    height: '485px',
                },
            },
        }).mount();

        setTimeout(() => {
            const heroImgWrapper = document.querySelector('.technology .hero-img-wrapper .hero-img-rect');
                if (heroImgWrapper) {
                    heroImgWrapper.classList.add('outline-animation');
                }
        }, 1000);

    },
    finalize() {
      // JavaScript to be fired on the home page, after the init JS
    },
};
