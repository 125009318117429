import { CountUp } from 'countup.js';

export default {
    init() {

    //Counters

    const counterOneData = document.querySelector('#counterOne');
    var counterOneField = counterOneData.dataset.value

    const counterTwoData = document.querySelector('#counterTwo');
    var counterTwoField = counterTwoData.dataset.value

    const counterThreeData = document.querySelector('#counterThree');
    var counterThreeField = counterThreeData.dataset.value

    let counterOne = new CountUp('counterOne', counterOneField, { duration: 3, enableScrollSpy: true, scrollSpyOnce: true } );
    counterOne.handleScroll();

    let counterTwo = new CountUp('counterTwo', counterTwoField, { duration: 3, enableScrollSpy: true, scrollSpyOnce: true } );
    counterTwo.handleScroll();

    let counterThree = new CountUp('counterThree', counterThreeField, { duration: 3, enableScrollSpy: true, scrollSpyOnce: true, suffix: '%' } );
    counterThree.handleScroll();

    },
    finalize() {
      // JavaScript to be fired on the home page, after the init JS
    },
  };
  