import Splide from '@splidejs/splide';
import GLightbox from 'glightbox';

export default {
    init() {

        const lightbox = GLightbox({
            touchNavigation: true,
            loop: true,
            autoplayVideos: true,
            preload: false,
        });
      
        lightbox.init();

        (function() {
            var splideGallery = document.querySelectorAll('.splide.gallery-carousel');      
            if(splideGallery.length){      
              for(var i=0; i<splideGallery.length; i++){
                var splideElement = splideGallery[i];      
                var splideDefaultOptions = {
                    perPage: 1,
                    focus: 'center',
                    type: 'loop',
                    padding: '26.27%',
                    gap: '53px',
                    arrows: true,
                    pagination: true,
                    updateOnMove: true,
                    breakpoints: {
                        1399.98: {
                          padding: 0,
                          gap: 0,
                          height: false,
                        },
                        1599.98: {
                          height: '272px',
                        },
                        1799.98: {
                          height: '314px',
                        },
                    },
                }      
                new Splide( splideElement, splideDefaultOptions ).mount();          
              }
            }      
        })();

        setTimeout(() => {
            const heroImgWrapper = document.querySelector('.photography .hero-img-wrapper .hero-img-rect');
                if (heroImgWrapper) {
                    heroImgWrapper.classList.add('outline-animation');
                }
        }, 1000);

    },
    finalize() {
      // JavaScript to be fired on the home page, after the init JS
    },
};
