// import external dependencies
import 'jquery';

// Import everything from autoload
import './autoload/_bootstrap.js'

// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import about from './routes/about';
import photography from './routes/photography';
import technology from './routes/technology';
import material from './routes/material';
import singlePost from './routes/single_post';

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common, 
  // Home page
  home,
  // About page
  about,
  // Photography
  photography,
  // Technology
  technology,
  // Material / Filament
  material,
  // Single post
  singlePost,
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());
